import styled from 'styled-components'
import gigplanetTheme from '../../styles/gigplanetTheme'

export const StyledSelectedValue = styled.div`
  border: 1px solid ${gigplanetTheme.element.default.border};
  border-radius: 2px;
  padding: 8px;
  margin: 0;
  background: #fafafa;
  width: 100%;
  font-size: 16px;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: space-between;
`

export const RemoveButton = styled.button.attrs(props => ({ type: 'button' }))`
  appearance: none;
  background: transparent;
  border: 0;
  cursor: pointer;
  font-size: 18px;
  line-height: 20px;
  margin: 0 0 0 5px;
  padding: 0;
  vertical-align: top;
  height: 20px;
  width: 20px;

  svg {
    fill: #000;
    height: 20px;
    width: 20px;
  }
`
