import * as React from 'react'
import PropTypes from 'prop-types'
import { compose } from 'recompose'
import { withRouter } from 'react-router'
import { connect } from 'react-redux'

import {
  resetArtistAdmin,
  setArtistFormData,
  saveArtistFormData,
  setHomeLocation as setHomeLocationAction,
  unsetHomeLocation as unsetHomeLocationAction,
} from '../../../stores/ArtistAdmin/ArtistAdminActions'
import { getCurrentUser, getIsFetching } from '../../../stores/CurrentUser/CurrentUserReducer'
import {
  getChildrenLocations,
  getLocationsIfNeeded,
} from '../../../stores/Locations/LocationsActions'
import { getArtistTypes } from '../../../stores/Options/OptionsReducer'
import {
  getArtistForm,
  getArtist,
  artistIsSaving,
} from '../../../stores/ArtistAdmin/ArtistAdminReducer'
import { getCounties } from '../../../stores/Locations/LocationsReducer'

import AccessDenied from '../../../components/AccessDenied'
import MetaData from '../../../components/MetaData'
import Layout from '../../../layouts/main-layout'
import JoinArtistForm from '../../../components/JoinArtistForm'
import { fetchSystemTextIfNeededAction } from '../../../stores/cms-content/actions'

const mapStateToProps = (state) => ({
  currentUser: getCurrentUser(state),
  loadingUser: getIsFetching(state),
  artistTypes: getArtistTypes(state),
  artistForm: getArtistForm(state),
  artistAdmin: getArtist(state),
  savingArtist: artistIsSaving(state),
  counties: getCounties(state),
})

const mapDispatchToProps = (dispatch) => ({
  setHomeLocation: (locationId) => {
    dispatch(setHomeLocationAction(locationId))
  },

  unsetHomeLocation: () => {
    dispatch(unsetHomeLocationAction())
  },

  setData: (data) => {
    dispatch(setArtistFormData(data))
  },

  saveData: () => {
    dispatch(saveArtistFormData())
  },

  reset: () => {
    dispatch(resetArtistAdmin())
  },

  getMunicipalities: (parentId) => {
    dispatch(getChildrenLocations(parentId))
  },
  fetchLocations: () => {
    dispatch(getLocationsIfNeeded(null))
  },
})

class JoinArtistRoute extends React.Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  componentDidMount() {
    const { reset, fetchLocations } = this.props
    reset()
    fetchLocations()
  }

  render() {
    const {
      currentUser,
      loadingUser,
      match: { params },
      artistTypes,
      artistForm,
      artistAdmin,
      savingArtist,
      setData,
      saveData,
      counties,
      setHomeLocation,
      unsetHomeLocation,
      getMunicipalities,
    } = this.props
    if (!{}.hasOwnProperty.call(currentUser, 'id') && !loadingUser) {
      return <AccessDenied />
    }

    let step = ''
    if (params && params.step) {
      step = params.step
    }

    return (
      <Layout simpleMenu hideFooter>
        <MetaData
          title="Registrer deg hos Gigplanet og få flere spillejobber"
          description="Gigplanet hjelper artister i hele Norge med å få flere spillejobber. Registrer deg i dag!"
        />
        <JoinArtistForm
          currentUser={currentUser}
          artistTypes={artistTypes}
          artistForm={artistForm}
          artistAdmin={artistAdmin}
          savingArtist={savingArtist}
          step={step}
          setData={setData}
          saveData={saveData}
        />
      </Layout>
    )
  }
}

JoinArtistRoute.propTypes = {
  currentUser: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  loadingUser: PropTypes.bool.isRequired,
  reset: PropTypes.func.isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      step: PropTypes.string,
    }).isRequired, // eslint-disable-line react/forbid-prop-types
  }).isRequired,
  setData: PropTypes.func.isRequired,
  saveData: PropTypes.func.isRequired,
  artistTypes: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  artistForm: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  artistAdmin: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  savingArtist: PropTypes.bool.isRequired,
  counties: PropTypes.array.isRequired, // eslint-disable-line react/forbid-prop-types
  getMunicipalities: PropTypes.func.isRequired,
  setHomeLocation: PropTypes.func.isRequired,
  unsetHomeLocation: PropTypes.func.isRequired,

  // state props

  // dispatch props
  fetchLocations: PropTypes.func.isRequired,
}

JoinArtistRoute.defaultProps = {}

JoinArtistRoute.dispatchActions = (req, dispatch) => [
  dispatch(getLocationsIfNeeded(req)),
  dispatch(fetchSystemTextIfNeededAction('join-artist')),
]

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(JoinArtistRoute)
