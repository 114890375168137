import styled from 'styled-components'
import gigplanetTheme from '../../styles/gigplanetTheme'

export const ContainerButton = styled.button.attrs((props) => ({ type: 'button' }))`
  border: 1px solid ${gigplanetTheme.element.default.border};
  border-radius: 2px;
  padding: 8px;
  margin: 0;
  background: ${gigplanetTheme.component.searchFilter.selectedElement.background};
  width: 100%;
  cursor: pointer;
`

export const Content = styled.span`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`

export const Value = styled.span`
  text-align: left;
  color: ${gigplanetTheme.component.searchFilter.selectedElement.text};
`
export const IconWrapper = styled.span`
  svg {
    fill: ${gigplanetTheme.component.searchFilter.selectedElement.icon};
    height: 20px;
    width: 20px;
  }
`
