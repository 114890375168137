import * as React from 'react'
import PropTypes from 'prop-types'

import { StyledSelectedValue, RemoveButton } from './style'
import { CloseIcon } from '../../icons'

const SelectedValue = ({ onRemove, name }) => (
  <StyledSelectedValue>
    <span>{name}</span>
    {onRemove ? (
      <RemoveButton onClick={onRemove}>
        <CloseIcon />
      </RemoveButton>
    ) : null}
  </StyledSelectedValue>
)

SelectedValue.propTypes = {
  name: PropTypes.string.isRequired,
  onRemove: PropTypes.func,
}

export default React.memo(SelectedValue)
