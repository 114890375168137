export const getTravelLocation = (addressComponents) => {
  let city = ''
  let county = ''

  if (!addressComponents && addressComponents.length === 0) {
    return { city, county }
  }
  addressComponents.forEach((component) => {
    const componentType = component.types[0]

    switch (componentType) {
      case 'administrative_area_level_1': {
        county = component.short_name
        break
      }
      case 'postal_town':
        city = component.long_name
        break
      default:
        // Do nothing. We are only looking for administrative_area_level_1 and postal_town
        break
    }
  })

  return { city, county }
}
