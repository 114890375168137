/* eslint-disable jsx-a11y/no-autofocus */
import * as React from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'

import Label from './Label'
import { CheckMarkCircleIcon } from '../../../icons'
import gigplanetTheme from '../../../styles/gigplanetTheme'

const Container = styled.div`
  max-width: 450px;
  min-width: 200px;
  position: relative;
  width: 100%;
  text-align: left;

  ${({ isExpanded }) =>
    isExpanded &&
    css`
      max-width: 100%;
    `};
  ${({ layout }) =>
    layout === 'stretch' &&
    css`
      height: 100%;
    `}
`
const Wrapper = styled.div`
  white-space: nowrap;
  display: flex;
  z-index: 999999;
  position: relative;
  ${({ layout }) =>
    layout === 'stretch' &&
    css`
      height: 100%;
    `}
`

const NotRequired = styled.span`
  font-size: 12px;
  margin-left: 10px;
`
const LabelText = styled.span``

const InputElement = styled.input`
  flex: 1 0 auto;
  appearance: none;
  background-color: ${gigplanetTheme.component.form.input.background};
  border: 1px solid ${gigplanetTheme.component.form.input.border};
  border-radius: 2px;
  color: ${gigplanetTheme.component.form.input.text};
  display: block;
  font-size: 16px;
  font-weight: 300;
  line-height: 20px;
  padding: 8px 10px;
  transition: border-color 0.2s ease-in-out;

  ${({ layout }) =>
    layout === 'stretch' &&
    css`
      height: 100%;
      border-radius: 5px;
      padding: 14px 12px;
      font-size: 18px;
      min-width: 300px;
    `}

  ${({ hasError }) =>
    hasError &&
    css`
      border: 1px solid ${gigplanetTheme.component.form.shared.errorText};
    `};

  ${({ isValidated }) =>
    isValidated &&
    css`
      padding-right: 38px;
    `};

  ${({ hasPostfix }) =>
    hasPostfix &&
    css`
      display: inline-block;
    `};

  &[disabled] {
    background-color: ${gigplanetTheme.component.form.input.backgroundDisabled};
    color: ${gigplanetTheme.component.form.input.textDisabled};
  }
`

const ValidatedWrapper = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  height: 38px;
  width: 34px;
  line-height: 38px;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    color: ${gigplanetTheme.component.form.input.validated};
    height: 24px;
    width: 24px;
  }
`

const LimitWrapper = styled.div`
  color: ${gigplanetTheme.component.form.input.limit};
  display: block;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  padding: 8px 10px;
  line-height: 20px;
  margin-top: 27px;
  position: absolute;
  right: 0;
  top: 0;
`

const HelpText = styled.small`
  color: ${gigplanetTheme.component.form.input.helpText};
  font-size: 12px;
  line-height: 15px;
`

const Postfix = styled.span`
  color: ${gigplanetTheme.component.form.input.text};
  display: inline-block;
  font-size: 16px;
  font-weight: 300;
  line-height: 20px;
  padding: 8px 10px;
  flex: 0 0 auto;
`

const ErrorMessage = styled.small`
  color: ${gigplanetTheme.component.form.shared.errorText};
  font-size: 12px;
  line-height: 15px;
`

const Input = React.forwardRef(
  (
    {
      label,
      hideLabel,
      helpText,
      placeholder,
      id,
      name,
      value,
      errorMessage,
      onChange,
      onBlur,
      onFocus,
      onKeyDown,
      labelPositioning,
      maxLength,
      maxTmpLength,
      expanded,
      disabled,
      required,
      type,
      autoFocus,
      postfix,
      labelHelp,
      validated,
      showValueMarker,
      showNotRequired,
      layout,
    },
    ref,
  ) => {
    let typeText = 'text'
    switch (type) {
      case 'text': {
        typeText = 'text'
        break
      }
      case 'search': {
        typeText = 'search'
        break
      }
      case 'number': {
        typeText = 'number'
        break
      }
      case 'email': {
        typeText = 'email'
        break
      }

      case 'password': {
        typeText = 'password'
        break
      }

      case 'tel': {
        typeText = 'tel'
        break
      }

      default: {
        typeText = 'text'
        break
      }
    }

    let limit = null
    if (maxLength && maxLength > 0) {
      const desc = value && value.length > 0 ? 'tegn igjen' : 'tegn'
      let restLength = maxLength - value.length
      if (restLength < 0) {
        restLength = 0
      }
      limit = <LimitWrapper>{`${restLength} ${desc}`}</LimitWrapper>
    }
    let postfixElement = null
    if (postfix && postfix.length > 0) {
      postfixElement = <Postfix>{postfix}</Postfix>
    }
    const maxLengthValue = maxLength || ''
    const input = (
      <InputElement
        layout={layout}
        type={typeText}
        ref={ref || null}
        id={id}
        name={name}
        value={value}
        maxLength={maxTmpLength || maxLengthValue}
        placeholder={placeholder}
        disabled={disabled}
        required={required}
        onChange={onChange}
        onBlur={onBlur}
        onFocus={onFocus}
        onKeyDown={onKeyDown}
        readOnly={disabled}
        autoFocus={autoFocus}
        hasError={!!errorMessage}
        isValidated={validated}
        hasPostfix={postfix && postfix.length > 0}
      />
    )

    const tmpLabel = (
      <Label
        htmlFor={id}
        labelHelp={labelHelp}
        hide={hideLabel}
        showValueMarker={showValueMarker}
        position={labelPositioning}
      >
        <LabelText>{label}</LabelText>
        {!required && showNotRequired ? <NotRequired>(Valgfritt)</NotRequired> : null}
      </Label>
    )

    const tmpHelpText = <HelpText>{helpText}</HelpText>
    const tmpErrorMessage = <ErrorMessage>{errorMessage}</ErrorMessage>

    const tmpValidated = (
      <ValidatedWrapper>
        <CheckMarkCircleIcon />
      </ValidatedWrapper>
    )

    const validatedElement = validated ? tmpValidated : null
    let helpTextElement = null
    if (errorMessage) {
      helpTextElement = tmpErrorMessage
    } else if (helpText) {
      helpTextElement = tmpHelpText
    }

    const labelElement = label ? tmpLabel : null
    return (
      <Container layout={layout} isExpanded={expanded}>
        {labelPositioning !== 'left' ? labelElement : null}
        {limit}
        <Wrapper layout={layout}>
          {labelPositioning === 'left' ? labelElement : null}
          {input}
          {postfixElement}
          {validatedElement}
        </Wrapper>
        {helpTextElement}
      </Container>
    )
  },
)

Input.propTypes = {
  label: PropTypes.string,
  hideLabel: PropTypes.bool,
  helpText: PropTypes.string,
  placeholder: PropTypes.string,
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  errorMessage: PropTypes.string,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  onFocus: PropTypes.func,
  onKeyDown: PropTypes.func,
  labelPositioning: PropTypes.string,
  maxLength: PropTypes.number,
  maxTmpLength: PropTypes.number,
  expanded: PropTypes.bool,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
  type: PropTypes.string,
  autoFocus: PropTypes.bool,
  postfix: PropTypes.string,
  labelHelp: PropTypes.string,
  validated: PropTypes.bool,
  showValueMarker: PropTypes.bool,
  showNotRequired: PropTypes.bool,
}

Input.defaultProps = {
  type: 'text',
  autoFocus: false,
  validated: false,
  hideLabel: false,
  showValueMarker: false,
  labelHelp: '',
  errorMessage: '',
  showNotRequired: false,
}

export default Input
