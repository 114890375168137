import { ARTIST_PROFILES_URL } from './Controlpanel'

export const AUTOSAVE_TIMEOUT = 3000
export const AUTOSAVE_EDITOR_TIMEOUT = 1000
export const AUTOSAVE_EDITOR_MAXWAIT = 2000

export const GENRES_MAX_COUNT = 5
export const OTHER_CATEGORIES_MAX_COUNT = 7
export const PROFILE_ADMIN_EDIT_START = 'START'
export const PROFILE_ADMIN_EDIT_INFO = 'INFO'
export const PROFILE_ADMIN_EDIT_FACTS = 'FACTS'
export const PROFILE_ADMIN_EDIT_TRAVEL = 'TRAVEL'
export const PROFILE_ADMIN_EDIT_IMAGES = 'IMAGES'
export const PROFILE_ADMIN_EDIT_AUDIO = 'AUDIO'
export const PROFILE_ADMIN_EDIT_VIDEO = 'VIDEO'
export const PROFILE_ADMIN_EDIT_REPERTOIRE = 'REPERTOIRE'
export const PROFILE_ADMIN_EDIT_GIGS = 'GIGS'
export const PROFILE_ADMIN_EDIT_CONTACT = 'CONTACT'

export const getRootUrl = (slug) => `${ARTIST_PROFILES_URL}/${slug}`

export const getDashboardUrl = (slug) => `${ARTIST_PROFILES_URL}/${slug}/dashboard`

export const getInboxUrl = (slug) => `${ARTIST_PROFILES_URL}/${slug}/inbox`

// TODO: Hardcoded category(band) now. Once we got more categories, we need to make some changes.
export const getPreviewUrl = (slug, type = '') => {
  if (type) {
    return `/${type}/${slug}`
  }
  return `/band/${slug}`
}

export const getEditName = () => 'Endre profil'

export const getEditStartName = () => 'Start'

export const getEditInfoName = () => 'Informasjon'

export const getEditFactsName = () => 'Fakta'

export const getEditTravelName = () => 'Reise'

export const getEditImagesName = () => 'Bilder'

export const getEditAudioName = () => 'Lyd'

export const getEditVideoName = () => 'Video'

export const getEditRepertoireName = () => 'Repertoar'

export const getEditGigsName = () => 'Jobber'

export const getEditContactName = () => 'Kontakt'

export const getEditUrl = (slug) => `${ARTIST_PROFILES_URL}/${slug}`

export const getEditStartUrl = (slug) => `${ARTIST_PROFILES_URL}/${slug}/start`

export const getEditInfoUrl = (slug) => `${ARTIST_PROFILES_URL}/${slug}/info`

export const getEditFactsUrl = (slug) => `${ARTIST_PROFILES_URL}/${slug}/facts`

export const getEditTravelUrl = (slug) => `${ARTIST_PROFILES_URL}/${slug}/travel`

export const getEditImagesUrl = (slug) => `${ARTIST_PROFILES_URL}/${slug}/images`

export const getEditAudioUrl = (slug) => `${ARTIST_PROFILES_URL}/${slug}/audio`

export const getEditVideoUrl = (slug) => `${ARTIST_PROFILES_URL}/${slug}/video`

export const getEditRepertoireUrl = (slug) => `${ARTIST_PROFILES_URL}/${slug}/repertoire`

export const getEditGigsUrl = (slug) => `${ARTIST_PROFILES_URL}/${slug}/gigs`

export const getEditContactUrl = (slug) => `${ARTIST_PROFILES_URL}/${slug}/contact`

export const getEditWelcomeUrl = (slug) => `${ARTIST_PROFILES_URL}/${slug}/welcome`

export const getEditMenu = (slug) => [
  {
    url: getEditStartUrl(slug),
    id: PROFILE_ADMIN_EDIT_START,
    name: getEditStartName(),
    desc: '',
  },
  {
    url: getEditInfoUrl(slug),
    id: PROFILE_ADMIN_EDIT_INFO,
    name: getEditInfoName(),
    desc: 'Rediger grunnleggende informasjon om profilen din',
  },
  {
    url: getEditFactsUrl(slug),
    id: PROFILE_ADMIN_EDIT_FACTS,
    name: getEditFactsName(),
    desc: 'Legg inn faktaopplysninger om deg/dere',
  },
  {
    url: getEditTravelUrl(slug),
    id: PROFILE_ADMIN_EDIT_TRAVEL,
    name: getEditTravelName(),
    desc: 'Legg inn reiseopplysninger om deg/dere',
  },
  {
    url: getEditImagesUrl(slug),
    id: PROFILE_ADMIN_EDIT_IMAGES,
    name: getEditImagesName(),
    desc: 'Bilder og beskrivelse',
  },
  {
    url: getEditAudioUrl(slug),
    id: PROFILE_ADMIN_EDIT_AUDIO,
    name: getEditAudioName(),
    desc: 'Last opp mp3 filer av deg/dere',
  },
  {
    url: getEditVideoUrl(slug),
    id: PROFILE_ADMIN_EDIT_VIDEO,
    name: getEditVideoName(),
    desc: 'Legg inn lenke til video av deg/dere fra Vimeo eller Youtube',
  },
  {
    url: getEditRepertoireUrl(slug),
    id: PROFILE_ADMIN_EDIT_REPERTOIRE,
    name: getEditRepertoireName(),
    desc: 'Legg in sangene du spiller her',
  },
  {
    url: getEditGigsUrl(slug),
    id: PROFILE_ADMIN_EDIT_GIGS,
    name: getEditGigsName(),
    desc: 'Beskrivelse av hvilke jobber dere ønsker å ha, og ikke ha',
  },
  {
    url: getEditContactUrl(slug),
    id: PROFILE_ADMIN_EDIT_CONTACT,
    name: getEditContactName(),
    desc: 'Kontaktinformasjon som brukes ved kontraktsinngåelse osv.',
  },
]

export const getIntroDescription = (slug) => ({
  tips: 'Fyll ut kort introduksjon',
  field: 'Kort introduksjon',
  containerId: 'profile-intro-container',
  url: getEditInfoUrl(slug),
})

export const getNameDescription = (slug) => ({
  tips: 'Fyll ut navn',
  field: 'Navn',
  containerId: 'profile-name-container',
  url: getEditInfoUrl(slug),
})

export const getDescriptionDescription = (slug) => ({
  tips: 'Fyll ut tekst om deg/dere',
  field: 'Om deg/dere',
  containerId: 'profile-description-container',
  url: getEditInfoUrl(slug),
})

export const getHomeLocationDescription = (slug) => ({
  tips: 'Fyll ut hjemfylke og kommune',
  field: 'Hjemfylke og kommune',
  containerId: 'home-location-container',
  url: getEditInfoUrl(slug),
})

export const getMembersDescription = (slug) => ({
  tips: 'Du må legge til minst ett medlem',
  field: 'Medlemmer',
  containerId: 'members-sub-heading-container',
  url: getEditInfoUrl(slug),
})

export const getArtistTypeDescription = (slug) => ({
  tips: 'Velg type artist',
  field: 'Type artist',
  containerId: 'profile-artist-type-container',
  url: getEditFactsUrl(slug),
})

export const getMainCategoryDescription = (slug) => ({
  tips: 'Velg hovedkategori',
  field: 'Hovedkategori',
  containerId: 'profile-main-category-container',
  url: getEditFactsUrl(slug),
})

export const getOtherCategoriesDescription = (slug) => ({
  tips: 'Velg flere kategorier',
  field: 'Flere kategorier',
  containerId: 'profile-others-category-container',
  url: getEditFactsUrl(slug),
})
export const getGenresDescription = (slug) => ({
  tips: 'Velg sjanger',
  field: 'Sjangere',
  containerId: 'profile-genres-container',
  url: getEditFactsUrl(slug),
})

export const getPlayTimeMaxDescription = (slug) => ({
  tips: 'Fyll ut maksimum spilletid',
  field: 'Maksimum spilletid',
  containerId: 'profile-play-time-max-container',
  url: getEditFactsUrl(slug),
})

export const getPriceMinDescription = (slug) => ({
  tips: 'Fyll ut minstepris',
  field: 'Minstepris',
  containerId: 'profile-price-min-container',
  url: getEditFactsUrl(slug),
})

export const getPlayedSinceDescription = (slug, artistType) => ({
  tips:
    artistType !== 'solo'
      ? 'Velg hvor lenge dere har spilt sammen'
      : 'Velg hvor lenge du har spilt',
  field: artistType !== 'solo' ? 'Spilt sammen siden' : 'Spilt siden',
  containerId: 'profile-played-toghether-since-container',
  url: getEditFactsUrl(slug),
})

export const getTravelDescription = (slug) => ({
  tips: 'Fyll ut reise',
  field: 'Adresse',
  containerId: 'profile-travel-container',
  url: getEditTravelUrl(slug),
})

export const getImagesDescription = (slug) => ({
  tips: 'Legg til bilder',
  field: 'Bilder',
  containerId: 'profile-images-container',
  url: getEditImagesUrl(slug),
})

export const getAudioDescription = (slug) => ({
  tips: 'Legg til lyd',
  field: 'Lyd',
  containerId: 'profile-audio-container',
  url: getEditAudioUrl(slug),
})

export const getVideoDescription = (slug) => ({
  tips: 'Legg til video',
  field: 'Video',
  containerId: 'profile-video-container',
  url: getEditVideoUrl(slug),
})

export const getRepertoireDescription = (slug) => ({
  tips: 'Fyll repertoar',
  field: 'Repertoar',
  containerId: 'profile-repertoire-container',
  url: getEditRepertoireUrl(slug),
})

export const getEventTypesDescription = (slug) => ({
  tips: 'Velg ønskede spillejobber',
  field: 'Ønskede spillejobber',
  containerId: 'profile-event-types-container',
  url: getEditGigsUrl(slug),
})

export const getPerformLocationsDescription = (slug) => ({
  tips: 'Velg ønskede spillesteder',
  field: 'Ønskede spillesteder',
  containerId: 'profile-perform-locations-container',
  url: getEditGigsUrl(slug),
})

export const getBookingEmailDescription = (slug) => ({
  tips: 'Fyll ut e-post til forespørsler',
  field: 'E-post til forespørsler',
  containerId: 'profile-booking-email-container',
  url: getEditContactUrl(slug),
})

export const getContactIsRepresentingDescription = (slug) => ({
  tips: 'Kryss av for juridisk kontaktperson',
  field: 'Juridisk kontaktperson',
  containerId: 'profile-contact-is-representing-container',
  url: getEditContactUrl(slug),
})

export const getOrganizationNumberDescription = (slug) => ({
  tips: 'Fyll ut organisasjonsnummer',
  field: 'Organisasjonsnummer',
  containerId: 'profile-contact-organization-number-container',
  url: getEditContactUrl(slug),
})

export const getInvoiceNameDescription = (slug) => ({
  tips: 'Fyll ut firmanavn',
  field: 'Firmanavn',
  containerId: 'profile-contact-invoice-name-container',
  url: getEditContactUrl(slug),
})

export const getInvoiceAddressDescription = (slug) => ({
  tips: 'Fyll ut fakturaadresse',
  field: 'Fakturaadresse',
  containerId: 'profile-contact-invoice-address-container',
  url: getEditContactUrl(slug),
})

export const getInvoiceZipDescription = (slug) => ({
  tips: 'Fyll ut postnummer til fakturaadress',
  field: 'Postnummer fakturaadresse',
  containerId: 'profile-contact-invoice-zip-container',
  url: getEditContactUrl(slug),
})

export const getInvoiceCityDescription = (slug) => ({
  tips: 'Fyll ut poststed til fakturaadress',
  field: 'Poststed fakturaadresse',
  containerId: 'profile-contact-invoice-city-container',
  url: getEditContactUrl(slug),
})

export const getContactNameDescription = (slug) => ({
  tips: 'Fyll ut navn til kontaktperson',
  field: 'Navn til kontaktperson',
  containerId: 'profile-contact-name-container',
  url: getEditContactUrl(slug),
})

export const getContactEmailDescription = (slug) => ({
  tips: 'Fyll ut e-post til kontaktperson',
  field: 'E-post til kontaktperson',
  containerId: 'profile-contact-email-container',
  url: getEditContactUrl(slug),
})

export const getContactPhoneDescription = (slug) => ({
  tips: 'Fyll ut telefon til kontaktperson',
  field: 'Telefon til kontaktperson',
  containerId: 'profile-contact-phone-container',
  url: getEditContactUrl(slug),
})
