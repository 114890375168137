import * as React from 'react'
import PropTypes from 'prop-types'

import AddressInput from '../../../../../../containers/profile-admin-edit-travel/components/address-input'
import { Wrapper as GoogleMapsWrapper, Status as GoogleMapsStatus } from '@googlemaps/react-wrapper'

import getGlobals from '../../../../../../helpers/getGlobals'
import { Container, Column, Select, Button } from './style'

const FrontPageHeaderSearch = ({
  counties,
  eventTypes,
  eventType,
  onEventTypeChange,
  onTravelChange,
  onSubmitSearch,
}) => {
  const performLocationOptions = React.useMemo(
    () =>
      counties.map((county) => (
        <option key={county.id} value={county.id}>
          {county.name}
        </option>
      )),
    [counties],
  )

  const eventTypeOptions = React.useMemo(() => {
    const groups = []
    Object.keys(eventTypes).forEach((index) => {
      const options = []
      Object.keys(eventTypes[index].sub).forEach((key) => {
        options.push(
          <option key={key} value={key}>
            {eventTypes[index].sub[key].name}
          </option>,
        )
      })
      groups.push(
        <optgroup label={eventTypes[index].longName} key={index}>
          {options}
        </optgroup>,
      )
    })
    return groups
  }, [eventTypes])

  const renderTravel = (status) => {
    switch (status) {
      case GoogleMapsStatus.LOADING:
        return ''
      case GoogleMapsStatus.SUCCESS:
        return (
          <AddressInput
            id="frontpage-search-travel"
            name="frontpage-search-travel"
            label={null}
            layout="stretch"
            helpText=" "
            onLocationChange={onTravelChange}
            placeholder="Søk etter sted"
          />
        )
      default:
        return ''
    }
  }

  return (
    <Container>
      <Column>
        <Select
          id="frontpage-search-event-type"
          aria-label="Hva skal du arrangere?"
          value={eventType}
          onChange={onEventTypeChange}
        >
          <option value="">Hva skal du arrangere?</option>
          {eventTypeOptions}
        </Select>
      </Column>
      <Column>
        <GoogleMapsWrapper
          apiKey={getGlobals().GOOGLE_MAPS_API_KEY}
          libraries={['places']}
          render={renderTravel}
        ></GoogleMapsWrapper>
      </Column>
      <Column>
        <Button type="button" onClick={onSubmitSearch}>
          Søk
        </Button>
      </Column>
    </Container>
  )
}

FrontPageHeaderSearch.propTypes = {
  eventType: PropTypes.string,
  eventTypes: PropTypes.shape({ name: PropTypes.string }).isRequired,
  onEventTypeChange: PropTypes.func.isRequired,
  counties: PropTypes.array, // eslint-disable-line react/forbid-prop-types
  onTravelChange: PropTypes.func.isRequired,
  onSubmitSearch: PropTypes.func.isRequired,
}

FrontPageHeaderSearch.defaultProps = {
  eventType: '',
  performLocation: '',
  counties: [],
}

export default FrontPageHeaderSearch
