/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, useRef } from 'react'
import PropTypes from 'prop-types'
import PlacesAutocomplete, {
  geocodeByAddress,
  geocodeByPlaceId,
  getLatLng,
} from 'react-places-autocomplete'
import { Input } from '../../../ui/Form'
import { AutocompleteContainer, AutocompleteItem, AddressInputWrapper, GoogleLogo } from './style'

const AddressInput = ({
  label,
  placeholder,
  name,
  id,
  onLocationChange,
  onSave,
  helpText,
  layout,
  currentAddress,
  currentLocationId,
}) => {
  const [address, setAddress] = useState('')
  const geoRef = useRef()

  const handleChange = (addr) => {
    setAddress(addr)
  }

  const handleSelect = (addr) => {
    geocodeByAddress(addr)
      .then((results) => {
        setAddress(results[0].formatted_address)
        geoRef.current = results[0]
        return getLatLng(results[0])
      })
      .then((latLng) => {
        onLocationChange(latLng.lat, latLng.lng, geoRef.current)
      })
      .catch((error) => console.error('Error', error))
  }

  const searchOptions = {
    componentRestrictions: { country: 'no' },
  }

  return (
    <PlacesAutocomplete
      value={address}
      onChange={handleChange}
      onSelect={handleSelect}
      searchOptions={searchOptions}
    >
      {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => {
        return (
          <AddressInputWrapper layout={layout}>
            <Input
              layout={layout}
              id={id || 'mapFieldSearch'}
              name={name || 'mapFieldSearch'}
              type="search"
              autocomplete="off"
              label={label == null ? '' : label || 'Adresse'}
              placeholder={placeholder || 'Skriv inn en adresse'}
              helpText={
                helpText ||
                'Adressen du taster inn vil aldri vises til kunde, men brukes for å beregne kjøretid til arrangementet. Et eksempel på adresse kan være øvingslokale eller stedet hvor dere oppbevarer utstyr.'
              }
              value={address}
              onBlur={onSave}
              {...getInputProps({
                placeholder: placeholder || 'Skriv inn en adresse til Acoustify',
                className: 'location-search-input',
              })}
            />
            <AutocompleteContainer>
              {loading && <div>Loading...</div>}
              {suggestions.map((suggestion) => {
                const className = suggestion.active ? 'suggestion-item--active' : 'suggestion-item'
                // inline style for demonstration purpose
                const style = suggestion.active
                  ? { backgroundColor: 'rgb(57 0 135 / 20%)', cursor: 'pointer' }
                  : { backgroundColor: '#ffffff', cursor: 'pointer' }
                return (
                  <AutocompleteItem
                    {...getSuggestionItemProps(suggestion, {
                      className,
                      style,
                    })}
                    key={suggestion.placeId}
                  >
                    <span>{suggestion.description}</span>
                  </AutocompleteItem>
                )
              })}
              {suggestions.length ? (
                <GoogleLogo src="/images/logos/google_on_white.png" alt="Google Logo" />
              ) : (
                ''
              )}
            </AutocompleteContainer>
          </AddressInputWrapper>
        )
      }}
    </PlacesAutocomplete>
  )
}

AddressInput.propTypes = {
  onLocationChange: PropTypes.func.isRequired,
  onSave: PropTypes.func,
  currentAddress: PropTypes.string,
  layout: PropTypes.string,
}

export default AddressInput
