import * as React from 'react'
import PropTypes from 'prop-types'

import { Container, Column, Select, Button } from './style'

const FrontPageHeaderSearch = ({
  counties,
  eventTypes,
  eventType,
  onEventTypeChange,
  performLocation,
  onPerformLocationChange,
  onSubmitSearch,
}) => {
  const performLocationOptions = React.useMemo(
    () =>
      counties.map((county) => (
        <option key={county.id} value={county.id}>
          {county.name}
        </option>
      )),
    [counties],
  )

  const eventTypeOptions = React.useMemo(() => {
    const groups = []
    Object.keys(eventTypes).forEach((index) => {
      const options = []
      Object.keys(eventTypes[index].sub).forEach((key) => {
        options.push(
          <option key={key} value={key}>
            {eventTypes[index].sub[key].name}
          </option>,
        )
      })
      groups.push(
        <optgroup label={eventTypes[index].longName} key={index}>
          {options}
        </optgroup>,
      )
    })
    return groups
  }, [eventTypes])

  return (
    <Container>
      <Column>
        <Select
          id="frontpage-search-event-type"
          aria-label="Hva skal du arrangere?"
          value={eventType}
          onChange={onEventTypeChange}
        >
          <option value="">Hva skal du arrangere?</option>
          {eventTypeOptions}
        </Select>
      </Column>
      <Column>
        <Select
          id="frontpage-search-location"
          aria-label="Hvor er ditt arrangement?"
          value={performLocation}
          onChange={onPerformLocationChange}
        >
          <option value="">Hvor er ditt arrangement?</option>
          {performLocationOptions}
        </Select>
      </Column>
      <Column>
        <Button type="button" onClick={onSubmitSearch}>
          Søk
        </Button>
      </Column>
    </Container>
  )
}

FrontPageHeaderSearch.propTypes = {
  eventType: PropTypes.string,
  eventTypes: PropTypes.shape({ name: PropTypes.string }).isRequired,
  onEventTypeChange: PropTypes.func.isRequired,
  counties: PropTypes.array, // eslint-disable-line react/forbid-prop-types
  performLocation: PropTypes.string,
  onPerformLocationChange: PropTypes.func.isRequired,
  onSubmitSearch: PropTypes.func.isRequired,
}

FrontPageHeaderSearch.defaultProps = {
  eventType: '',
  performLocation: '',
  counties: [],
}

export default FrontPageHeaderSearch
