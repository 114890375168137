/* globals window */
import * as React from 'react'
import PropTypes from 'prop-types'
import Scroll from 'react-scroll'
import styled from 'styled-components'

import { trackEvent } from '../../../../libs/analytics'

import RatingStars from '../../../../ui/RatingStars'
import PublicGenresList from '../../../../containers/PublicGenresList/PublicGenresList'
import FavoriteButton from '../../../../containers/favorite-button'
import Infoline from './components/Infoline'
import Image from './components/Image'

import {
  Container,
  Content,
  Row,
  Info,
  Heading,
  Slogan,
  MemberText,
  RatingContainer,
  RatingStarsContainer,
  RatingDescription,
  HeaderIntro,
  GenresContainer,
  GenresContainerNoMargin,
  FavoriteWrapper,
} from './style'
import gigplanetTheme from '../../../../styles/gigplanetTheme'
import mediaQueries from '../../../../styles/mediaQueries'
import { getTravelLocation } from '../../../../helpers/getTravelLocation'

const ImageContainer = styled.div`
  position: relative;
  min-height: 150px;
  float: left;
  display: block;
  margin-right: 2.35765%;
  width: 48.82117%;

  ${mediaQueries.mobile`
    float: left;
    display: block;
    margin-right: 2.35765%;
    width: 100%;
  `};

  ${mediaQueries.tabletPortrait`
  `};
`

const MoreImagesButton = styled.button`
  position: absolute;
  right: 10px;
  bottom: 10px;
  background: white;
  padding: 6px 12px;
  font-size: 16px;
  line-height: 1.5;
  border-radius: 4px;
  color: ${gigplanetTheme.color.darkGray};
  border: 0;
  cursor: pointer;
`

const scroll = () => {
  const id = 'anmeldelser'
  window.history.replaceState({}, '', `#${id}`)
  Scroll.scroller.scrollTo(id, {
    duration: 1000,
    delay: 0,
    offset: -50,
    smooth: 'easeInOutQuint',
  })
}

const ProfileDetailsHeader = React.memo(
  ({
    artistName,
    artistId,
    artistTravel,
    images = [],
    onOpenImageGallery,
    rating = null,
    slogan = '',
    memberText = '',
    artistGenres = [],
    intro = '',
    artistType,
    homeLocation = [],
    memberCount = 0,
    playedSince = 0,
    minPrice,
  }) => {
    const handleShowMoreImages = (e) => {
      e.preventDefault()
      trackEvent(
        'artist-profile',
        'artist-profile-show-image-gallery-from-header-image-button',
        artistName,
      )
      onOpenImageGallery(0)
    }

    const { city, county } = React.useMemo(() => {
      let tCity = ''
      let tCounty = ''
      if (artistTravel && artistTravel.geo) {
        const travelLocation = getTravelLocation(artistTravel.geo.address_components)
        tCity = travelLocation.city
        tCounty = travelLocation.county
      }
      return { city: tCity, county: tCounty }
    }, [artistTravel])

    return (
      <Container>
        <Content>
          <Row>
            <ImageContainer>
              <Image
                onOpenImageGallery={onOpenImageGallery}
                images={images}
                artistName={artistName}
              />
              <FavoriteWrapper>
                <FavoriteButton artistName={artistName} artistId={artistId} round />
              </FavoriteWrapper>
              {images && images.length > 1 ? (
                <MoreImagesButton onClick={handleShowMoreImages} type="button">
                  Se bilder
                </MoreImagesButton>
              ) : null}
            </ImageContainer>
            <Info>
              <Heading itemProp="name">{artistName}</Heading>
              {
                // eslint-disable-next-line react/no-danger
                slogan ? <Slogan dangerouslySetInnerHTML={{ __html: slogan }} /> : null
              }
              {memberText ? <MemberText>{memberText}</MemberText> : null}
              {rating && rating.reviews > 0 ? (
                <RatingContainer
                  href="#anmeldelser"
                  itemProp="aggregateRating"
                  itemScope
                  itemType="http://schema.org/AggregateRating"
                  onClick={(e) => {
                    e.preventDefault()
                    scroll()
                  }}
                >
                  <RatingStarsContainer>
                    <RatingStars rating={rating.average} size="medium" />
                    <meta itemProp="ratingValue" content={rating.average} />
                    <meta itemProp="reviewCount" content={rating.reviews} />
                  </RatingStarsContainer>
                  <RatingDescription>
                    {rating.reviews} {rating.reviews === 1 ? 'anmeldelse' : 'anmeldelser'}
                  </RatingDescription>
                </RatingContainer>
              ) : null}
              {artistGenres && artistGenres.length > 0 ? (
                <>
                  {rating && rating.reviews > 0 ? (
                    <GenresContainerNoMargin>
                      <PublicGenresList leftAlign mobileCenter large genres={artistGenres} />
                    </GenresContainerNoMargin>
                  ) : (
                    <GenresContainer>
                      <PublicGenresList leftAlign mobileCenter large genres={artistGenres} />
                    </GenresContainer>
                  )}
                </>
              ) : null}
              {intro ? <HeaderIntro itemProp="description">{intro}</HeaderIntro> : null}
              <Infoline
                artistType={artistType}
                city={city}
                county={county}
                homeLocation={homeLocation}
                memberCount={memberCount}
                playedSince={playedSince}
                minPrice={minPrice}
              />
            </Info>
          </Row>
        </Content>
      </Container>
    )
  },
)

ProfileDetailsHeader.propTypes = {
  artistName: PropTypes.string.isRequired,
  artistId: PropTypes.string.isRequired,
  artistType: PropTypes.string.isRequired,
  artistTravel: PropTypes.shape({
    location: PropTypes.any.isRequired,
    geo: PropTypes.any.isRequired,
  }),
  slogan: PropTypes.string,
  memberText: PropTypes.string,
  intro: PropTypes.string,
  images: PropTypes.array, // eslint-disable-line react/forbid-prop-types
  onOpenImageGallery: PropTypes.func.isRequired,
  artistGenres: PropTypes.array, // eslint-disable-line react/forbid-prop-types
  rating: PropTypes.shape({
    average: PropTypes.number.isRequired,
    count: PropTypes.number.isRequired,
    reviews: PropTypes.number.isRequired,
    total: PropTypes.number.isRequired,
  }),
  homeLocation: PropTypes.array, // eslint-disable-line react/forbid-prop-types
  memberCount: PropTypes.number,
  playedSince: PropTypes.number,
  minPrice: PropTypes.number,
}

export default ProfileDetailsHeader
