import styled from 'styled-components'
import gigplanetTheme from '../../../styles/gigplanetTheme'
import mediaQueries from '../../../styles/mediaQueries'

export const KickbackList = styled.ul`
  max-width: 450px;
  width: 100%;
  list-style: none;
  margin: 0;
  padding: 0 33px 30px 0;
`

export const KickbackItem = styled.li`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-top: 1px solid ${gigplanetTheme.element.default.border};
  line-height: 40px;
  &:last-child {
    border-bottom: 1px solid ${gigplanetTheme.element.default.border};
  }
`

export const KickbackLabel = styled.span`
  font-size: 16px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  ${mediaQueries.mobile`
    font-size: 12px;
  `};
`

export const KickbackValue = styled.span`
  font-size: 16px;
  font-weight: bold;
  white-space: nowrap;
  margin-left: 15px;

  ${mediaQueries.mobile`
    font-size: 12px;
  `};
`

export const CheckboxList = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
`

export const CheckboxItem = styled.li`
  margin-bottom: 10px;

  &:last-child {
    margin: 0;
  }
`

export const DistanceWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  margin-top: 15px;
`

export const DistanceNumber = styled.span``

export const DistanceSliderContainer = styled.div`
  flex: 1 0 auto;
  padding: 0 20px;

  .rc-slider-handle:active {
    box-shadow: 0 0 5px ${gigplanetTheme.color.primary};
  }
`

export const AddressInputWrapper = styled.div`
  position: relative;
  ${(props) =>
    props.layout === 'stretch' &&
    `
      height: 100%;
    `}
`

export const AutocompleteContainer = styled.div`
  position: absolute;
  display: block;
  box-shadow: 0 7px 10px rgba(0, 0, 0, 0.2);
  top: 64px;
  z-index: 10000000;
  background: #fff;
  display: flex;
  flex-direction: column;
  max-width: 450px;
  min-width: 200px;
  width: 100%;
  text-align: left;
`
export const AutocompleteItem = styled.div`
  padding: 10px;
  background: #fff;
`

export const GoogleLogo = styled.img`
  width: 59px;
  margin: 10px 10px;
`

export const DistanceSliderTrack = { backgroundColor: gigplanetTheme.color.primary }
export const DistanceSliderHandle = { borderColor: gigplanetTheme.color.primary }

export const DistanceLabel = styled.span``
