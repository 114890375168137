import * as React from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router'

import {
  Container,
  Content,
  Footer,
  ProgressWrapper,
  ProgressBarWrapper,
  ProgressTextWrapper,
  ProgressLabel,
  ProgressPercentage,
} from './style'
import ProgressBar from '../../ui/ProgressBar'
import Button from '../../ui/Button'
import UserAuthWindow from '../../containers/user-auth-window'
import JoinArtistLegal from './components/JoinArtistLegal'
import JoinArtistName from './components/JoinArtistName'
import JoinArtistType from './components/JoinArtistType'
import JoinArtistLocation from './components/JoinArtistLocation'
import JoinArtistContact from './components/JoinArtistContact'
import PageNotFound from '../PageNotFound'

const renderError = () => <PageNotFound />

class JoinArtistForm extends React.Component {
  UNSAFE_componentWillReceiveProps(nextProps) {
    const { step, history } = this.props
    if (step !== 'legal' && step !== 'name') {
      if (!nextProps.artistAdmin.id && !nextProps.savingArtist) {
        history.push('/join/artist/legal')
      }
    }
  }

  getProgress = () => {
    const { step } = this.props
    switch (step) {
      case 'legal': {
        return 0
      }
      case 'name': {
        return 0.2
      }

      case 'type': {
        return 0.4
      }

      case 'location': {
        return 0.6
      }

      case 'contact': {
        return 0.8
      }
      default: {
        return 0
      }
    }
  }

  renderContent = () => {
    const {
      artistAdmin,
      savingArtist,
      currentUser,
      step,
      artistForm,
      setData,
      saveData,
      artistTypes,
    } = this.props

    if (!currentUser) {
      return <UserAuthWindow />
    }

    if (step !== 'legal' && step !== 'name') {
      if (!artistAdmin.id && !savingArtist) {
        return renderError()
      }
    }

    switch (step) {
      case 'legal': {
        return (
          <JoinArtistLegal
            currentUser={currentUser}
            artistForm={artistForm}
            setData={setData}
            saveData={saveData}
          />
        )
      }
      case 'name': {
        return (
          <JoinArtistName
            currentUser={currentUser}
            artistForm={artistForm}
            setData={setData}
            saveData={saveData}
          />
        )
      }

      case 'type': {
        return (
          <JoinArtistType
            currentUser={currentUser}
            artistTypes={artistTypes}
            artistForm={artistForm}
            setData={setData}
            saveData={saveData}
          />
        )
      }

      case 'location': {
        return (
          <JoinArtistLocation
            currentUser={currentUser}
            artistTypes={artistTypes}
            artistForm={artistForm}
            setData={setData}
            saveData={saveData}
          />
        )
      }

      case 'contact': {
        return (
          <JoinArtistContact
            currentUser={currentUser}
            artistAdmin={artistAdmin}
            artistForm={artistForm}
            setData={setData}
            saveData={saveData}
          />
        )
      }
      default: {
        return null
      }
    }
  }

  renderProgress = () => {
    const { currentUser } = this.props
    if (!currentUser) {
      return null
    }
    return (
      <ProgressWrapper>
        <ProgressBarWrapper>
          <ProgressBar isPercentage={false} progress={this.getProgress()} thin />
        </ProgressBarWrapper>
        <ProgressTextWrapper>
          <ProgressLabel>Registrer artist</ProgressLabel>
          <ProgressPercentage>{this.getProgress() * 100}% ferdig</ProgressPercentage>
        </ProgressTextWrapper>
      </ProgressWrapper>
    )
  }

  render() {
    const { history } = this.props
    return (
      <Container>
        <Content>
          {this.renderContent()}
          {this.renderProgress()}
          <Footer>
            <Button
              type="button"
              theme="cancel"
              onClick={() => {
                history.push('/')
              }}
            >
              Avbryt og gå til forsiden
            </Button>
          </Footer>
        </Content>
      </Container>
    )
  }
}

JoinArtistForm.propTypes = {
  currentUser: PropTypes.object, // eslint-disable-line react/forbid-prop-types
  artistTypes: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  artistForm: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  artistAdmin: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  savingArtist: PropTypes.bool.isRequired,
  step: PropTypes.string,
  setData: PropTypes.func.isRequired,
  saveData: PropTypes.func.isRequired,

  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
}

JoinArtistForm.defaultProps = {}

export default withRouter(JoinArtistForm)
