import PropTypes from 'prop-types'
import { getTravelLocation } from '../helpers/getTravelLocation'
import { getCropUrl } from '../helpers/ImgixCropUrl'

export const JOIN_ARTIST_URL = '/join'
export const JOIN_START_ARTIST_URL = '/join/artist'

export const ArtistShape = PropTypes.shape({
  id: PropTypes.string,
  slug: PropTypes.string,
  name: PropTypes.string,
  artistType: PropTypes.string,
  images: PropTypes.array,
})

export const artistSearchOrderBy = [
  {
    name: 'Relevans',
    sortField: 'relevance',
    sortDir: 'desc',
  },
  {
    name: 'Sist publiserte',
    sortField: 'publishedTime',
    sortDir: 'desc',
  },
  {
    name: 'Pris høy-lav',
    sortField: 'priceMin',
    sortDir: 'desc',
  },
  {
    name: 'Pris lav - høy',
    sortField: 'priceMin',
    sortDir: 'asc',
  },
  {
    name: 'Navn',
    sortField: 'name',
    sortDir: 'asc',
  },
]

export const ARTIST_IMAGE_WIDTH = 980
export const ARTIST_IMAGE_HEIGHT = 600

export const ARTIST_IMAGE_THUMB_WIDTH = 380 // admin
export const ARTIST_IMAGE_THUMB_HEIGHT = 230 // admin

const ARTIST_IMAGE_IMGIX_SETTINGS_CROP = '?auto=compress,format&q=60&crop=faces&fit=crop'
const ARTIST_IMAGE_IMGIX_SETTINGS_ORIGINAL = '?auto=compress,format&q=60'

export const ARTIST_IMAGE_IMGIX_FORMAT_SHARING = '&w=1200&h=630'
export const ARTIST_IMAGE_IMGIX_FORMAT_MAIN_DESKTOP = '&w=650&h=365'
export const ARTIST_IMAGE_IMGIX_FORMAT_MAIN_TABLET = '&w=560&h=315'
export const ARTIST_IMAGE_IMGIX_FORMAT_MAIN_MOBILE = '&w=600&h=338'

export const ARTIST_IMAGE_IMGIX_FORMAT_PROFILE_DESKTOP = '&w=650&h=365'

export const ARTIST_IMAGE_IMGIX_FORMAT_GALLERY_DESKTOP = '&w=2000&h=1200'
export const ARTIST_IMAGE_IMGIX_FORMAT_GALLERY_TABLET = '&w=1500&h=900'
export const ARTIST_IMAGE_IMGIX_FORMAT_GALLERY_MOBILE = '&w=600&h=800'

export const ARTIST_IMAGE_IMGIX_FORMAT_SMALL_THUMB = '&w=42&h=30'
export const ARTIST_IMAGE_IMGIX_FORMAT_THUMB = '&w=100&h=70'
export const ARTIST_IMAGE_IMGIX_FORMAT_LIST_DESKTOP = '&w=650&h=365'
export const ARTIST_IMAGE_IMGIX_FORMAT_LIST_TABLET = '&w=560&h=315'
export const ARTIST_IMAGE_IMGIX_FORMAT_LIST_MOBILE = '&w=600&h=338'

export const PUBLISH_STATUS = {
  DRAFT: 'DRAFT',
  PUBLISHED: 'PUBLISHED',
  AWAITING_APPROVAL: 'AWAITING_APPROVAL',
  DECLINED: 'DECLINED',
}

export const PUBLISH_STATUSES = [
  PUBLISH_STATUS.PUBLISHED,
  PUBLISH_STATUS.AWAITING_APPROVAL,
  PUBLISH_STATUS.DRAFT,
  PUBLISH_STATUS.DECLINED,
]

export const getPublishStatusName = (status) => {
  switch (status) {
    case PUBLISH_STATUS.DRAFT:
      return 'Utkast'
    case PUBLISH_STATUS.PUBLISHED:
      return 'Publisert'
    case PUBLISH_STATUS.AWAITING_APPROVAL:
      return 'Venter godkjenning'
    case PUBLISH_STATUS.DECLINED:
      return 'Avvist'
    default:
      return 'Ukjent status'
  }
}

export const devideInStatus = (artists) => {
  const artistsArray = {
    [PUBLISH_STATUS.PUBLISHED]: {
      name: getPublishStatusName(PUBLISH_STATUS.PUBLISHED),
      artists: [],
    },
    [PUBLISH_STATUS.AWAITING_APPROVAL]: {
      name: getPublishStatusName(PUBLISH_STATUS.AWAITING_APPROVAL),
      artists: [],
    },
    [PUBLISH_STATUS.DRAFT]: {
      name: getPublishStatusName(PUBLISH_STATUS.DRAFT),
      artists: [],
    },
    [PUBLISH_STATUS.DECLINED]: {
      name: getPublishStatusName(PUBLISH_STATUS.DECLINED),
      artists: [],
    },
  }
  artists.forEach((artist) => {
    artistsArray[artist.status].artists.push(artist)
  })
  return artistsArray
}

export const getImgixCroppedUrl = (src, format, crop = '', dpr = 1) => {
  const cropString = getCropUrl(crop)
  const rect = cropString ? `&rect=${encodeURIComponent(cropString)}` : ''
  return `${src}${ARTIST_IMAGE_IMGIX_SETTINGS_CROP}${format}${rect}&dpr=${dpr}`
}

export const getImgixUrl = (src, format, dpr = 1) =>
  `${src}${ARTIST_IMAGE_IMGIX_SETTINGS_ORIGINAL}${format}&dpr=${dpr}`

export const ARTIST_MEMBER_TYPES = {
  musician: 'musician',
  conductor: 'conductor',
  dancer: 'dancer',
  dj: 'dj',
}
export const DEFAULT_ARTIST_MEMBER_TYPES = {
  [ARTIST_MEMBER_TYPES.musician]: true,
  [ARTIST_MEMBER_TYPES.conductor]: false,
  [ARTIST_MEMBER_TYPES.dancer]: false,
  [ARTIST_MEMBER_TYPES.dj]: false,
}

export const DEFAULT_MEMBER_OBJECT = {
  firstname: '',
  lastname: '',
  phone: '',
  instruments: [],
  description: '',
  memberTypes: DEFAULT_ARTIST_MEMBER_TYPES,
  danceTypes: [],
}

const extractId = (url, prefixes) => {
  const cleaned = url.replace(/^(https?:)?\/\/(www\.)?/, '')
  for (let i = 0; i < prefixes.length; i += 1) {
    if (cleaned.indexOf(prefixes[i]) === 0) {
      return cleaned.substr(prefixes[i].length)
    }
  }
  return undefined
}

export const getMemberText = (artist) => {
  let memberText = ''
  if (artist.memberCounts && artist.memberCounts.length > 0) {
    memberText = 'Kan også stille med '
    if (artist.memberCounts.length > 1) {
      artist.memberCounts.forEach((num, i) => {
        memberText += num
        if (i === artist.memberCounts.length - 2) {
          memberText += ' eller '
        } else if (i < artist.memberCounts.length - 2) {
          memberText += ', '
        }
      })
    } else {
      memberText += artist.memberCounts[0]
    }

    memberText += ' medlemmer'
  }
  return memberText
}

export const checkIfMemberCountShouldBeDisplayed = (category) => {
  switch (category) {
    case 'band_duo':
    case 'band_trio':
      return false
    default:
      return true
  }
}

export const getSlogan = (artist, categories, brTag = false) => {
  const memberCount = artist.members ? artist.members.length : 0
  let city, county

  if (artist && artist.travel && artist.travel.geo) {
    ;({ city, county } = getTravelLocation(artist.travel.geo.address_components))
  }

  let slogan = ''
  if (
    artist.artistType &&
    artist.mainCategory &&
    categories[artist.artistType] &&
    categories[artist.artistType].sub[artist.mainCategory]
  ) {
    slogan += categories[artist.artistType].sub[artist.mainCategory].name
    if (city) {
      slogan += ` fra ${city}`
    } else if (artist.homeLocation && artist.homeLocation.length === 2) {
      slogan += ` fra ${artist.homeLocation[1].name}`
    }

    if (
      memberCount > 1 &&
      artist.artistType !== 'solo' &&
      checkIfMemberCountShouldBeDisplayed(artist.mainCategory)
    ) {
      slogan += brTag ? ` <br />med ${memberCount} medlemmer` : ` med ${memberCount} medlemmer`
    }
  }
  return slogan
}

export const getMetaTitle = (artist, categories) =>
  `Book ${artist.name} - ${getSlogan(artist, categories)}`

export const getMetaDescription = (artist) => {
  const metaLength = 160
  const more = '... '
  const ending = 'Se, hør og book her!'
  const length = metaLength - more.length - ending.length

  if (artist.intro && artist.intro.length > length) {
    return `${artist.intro.substring(0, length)}${more}${ending}`
  }
  return `${artist.intro} ${ending}`
}

export const getShortSlogan = (artist, categories) => {
  let slogan = ''
  let city = ''

  if (artist && artist.travel && artist.travel.geo) {
    const travelLocation = getTravelLocation(artist.travel.geo.address_components)
    city = travelLocation.city
  }

  if (
    artist.artistType &&
    artist.mainCategory &&
    categories[artist.artistType] &&
    categories[artist.artistType].sub[artist.mainCategory]
  ) {
    slogan += categories[artist.artistType].sub[artist.mainCategory].name

    if (city !== '') {
      slogan += ` fra ${city}`
    } else if (artist.homeLocation && artist.homeLocation.length === 2) {
      slogan += ` fra ${artist.homeLocation[1].name}`
    }
  } else if (city !== '') {
    slogan += `Fra ${city}`
  } else if (artist.homeLocation && artist.homeLocation.length === 2) {
    slogan += `Fra ${artist.homeLocation[1].name}`
  }
  return slogan
}

const getYouTubeId = (url) =>
  extractId(url, [
    'youtube.com/watch?v=',
    'youtu.be/',
    'youtube.com/embed/',
    'youtube.googleapis.com/v/',
  ])

const getVimeoId = (url) =>
  extractId(url, ['vimeo.com/', 'player.vimeo.com/', 'vimeo.com/channels/staffpicks/'])

export const getFactPlayedSince = () => {
  const date = new Date()
  const year = parseInt(date.getFullYear(), 10)
  const years = []
  for (let i = year; i >= 1970; i -= 1) {
    years.push(i)
  }

  return years
}
export const printArtistRoles = (
  memberTypes,
  types,
  memberInstruments,
  instruments,
  memberDanceTypes,
  danceTypes,
) => {
  const roles = []
  console.log('instruments', instruments)
  if (Array.isArray(memberInstruments)) {
    memberInstruments.forEach((key) => roles.push(instruments[key].name))
  } else {
    Object.keys(memberInstruments).forEach((key) => roles.push(instruments[key].name))
  }
  memberDanceTypes.forEach((key) => roles.push(danceTypes[key].name))

  /** Filter out roles defined by instruments & dance types */
  Object.keys(memberTypes).forEach((mt) => {
    if (
      memberTypes[mt] &&
      types[mt] &&
      mt !== ARTIST_MEMBER_TYPES.musician &&
      mt !== ARTIST_MEMBER_TYPES.dancer
    ) {
      roles.push(types[mt].name)
    }
  })

  let textString = ''
  if (roles.length > 1) {
    for (let i = 0; i < roles.length; i += 1) {
      textString += roles[i]
      if (i < roles.length - 1) {
        if (i < roles.length - 2) {
          textString += ', '
        } else {
          textString += ' og '
        }
      }
    }
  } else {
    textString += roles[0] ? roles[0] : ''
  }
  return textString
}
export const printInstruments = (memberInstruments, instruments) => {
  const inst = []
  if (Array.isArray(memberInstruments)) {
    memberInstruments.forEach((key) => inst.push(instruments[key]))
  } else {
    Object.keys(memberInstruments).forEach((key) => inst.push(instruments[key]))
  }
  let textString = ''
  if (inst.length > 1) {
    for (let i = 0; i < inst.length; i += 1) {
      textString += inst[i].instrument
      if (i < inst.length - 1) {
        if (i < inst.length - 2) {
          textString += ', '
        } else {
          textString += ' og '
        }
      }
    }
  } else {
    textString += inst[0] ? inst[0].name : ''
  }
  return textString
}
