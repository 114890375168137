import styled from 'styled-components'

import mediaQueries from '../../../../../../styles/mediaQueries'
import gigplanetTheme from '../../../../../../styles/gigplanetTheme'

export const Container = styled.div`
  align-items: flex-end;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;

  ${mediaQueries.tabletPortraitDown`
    align-items: flex-start;
    flex-direction: column;
    justify-content: flex-start;
  `};
`
export const Column = styled.div`
  margin-left: 2.5rem;

  ${mediaQueries.tabletPortraitDown`
    margin-left: 0;
    margin-top: 1.5rem;
    width: 100%;
  `};

  &:first-child {
    margin-left: 0;
    margin-top: 0;
  }
  &:nth-child(2) {
    align-self: stretch;
  }
`

export const Select = styled.select`
  appearance: none;
  background-color: ${gigplanetTheme.component.form.select.background};
  background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' version='1.1' width='32' height='24' viewBox='0 0 32 24'><polygon points='0,0 32,0 16,24' style='fill: rgb%28138, 138, 138%29'></polygon></svg>");
  background-origin: content-box;
  background-position: right 0 center;
  background-repeat: no-repeat;
  background-size: 9px 6px;
  border: 1px solid ${gigplanetTheme.component.form.select.border};
  border-radius: 5px;
  color: ${gigplanetTheme.component.form.select.text};
  font-size: 18px;
  line-height: 24px;
  margin: 0;
  min-width: 300px;
  padding: 14px 12px;
  position: relative;
  text-transform: none;
  width: 100%;
  z-index: ${gigplanetTheme.zIndex.formElements};

  ${mediaQueries.mobile`
    display: block;
    font-size: 16px;
    padding: 10px 12px;
  `};
`

export const Button = styled.button`
  border-radius: 5px;
  border: 0;
  background: ${gigplanetTheme.component.button.primary.background};
  color: ${gigplanetTheme.component.button.primary.text};
  appearance: none;
  font-size: 18px;
  line-height: 32px;
  padding: 10px 62px;
  display: inline-block;
  word-wrap: normal;
  cursor: pointer;
  text-decoration: none;
  white-space: nowrap;

  ${mediaQueries.tabletPortrait`
    font-size: 18px;
  `};

  ${mediaQueries.mobile`
    font-size: 16px;
    line-height: 24px;
    margin-left: 0;
    padding: 11px 22px;
    width: 100%;
  `};
`
