import * as React from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router'

import { Row, Actions, Subheading, Intro, InputWrapper } from './style'
import { Select, Errormessage } from '../../../ui/Form'
import { H1 } from '../../../ui/Headings'
import Button from '../../../ui/Button'

import { Wrapper, Status } from '@googlemaps/react-wrapper'
import SelectedValue from '../../../ui/selected-value'
import AddressInput from '../../../containers/profile-admin-edit-travel/components/address-input'
import getGlobals from '../../../helpers/getGlobals'

class JoinArtistLocation extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      errorMessage: [],
    }
  }

  // What name does your act or service go by?
  handleNext = (e) => {
    const { artistForm, history, saveData } = this.props

    e.preventDefault()
    if (artistForm.travel && artistForm.travel.geo && artistForm.travel.geo.formatted_address) {
      saveData()
      history.push('/join/artist/contact/')
    } else {
      this.setState({
        errorMessage: ['Du må oppgi adressen din'],
      })
    }
  }

  handlePrevious = (e) => {
    const { history } = this.props
    e.preventDefault()
    history.push('/join/artist/type/')
  }

  handleArtistTypeChange = (e) => {
    const { setData } = this.props
    setData({ artistType: e.currentTarget.value })
  }

  handleLocationChange = (lat, lng, geo) => {
    const { setData } = this.props
    setData({ travel: { location: [lng, lat], geo, acceptExtendedTravel: false } })
    this.setState({ geo })
  }

  handleTravelReset = () => {
    this.updateData({
      geo: {},
      location: [],
      maxDistance: 0,
      acceptExtendedTravel: false,
    })
  }

  renderMap = (status) => {
    if (status === Status.LOADING) return <h3>{status} ..</h3>
    if (status === Status.FAILURE) return <h3>{status} ...</h3>
    return null
  }

  render() {
    const { currentUser, artistTypes, artistForm } = this.props
    const { errorMessage, geo } = this.state

    return (
      <>
        <Row>
          <H1>
            Okey {currentUser.firstName}, så du er {artistTypes[artistForm.artistType].name}
          </H1>
        </Row>
        <Row>
          <Subheading>Nå trenger vi å vite hvor du holder til</Subheading>
        </Row>
        <Row>
          <Intro />
        </Row>
        <Row>
          <Wrapper
            apiKey={getGlobals().GOOGLE_MAPS_API_KEY}
            libraries={['places']}
            render={this.renderMap}
          >
            <InputWrapper>
              {artistForm.travel.geo.length && geo && geo.formatted_address ? (
                <SelectedValue
                  name={artistForm.travel.geo.formatted_address}
                  onRemove={this.handleTravelReset}
                />
              ) : (
                <AddressInput
                  onLocationChange={this.handleLocationChange}
                  onSave={this.handleLocationChange}
                  placeholder={`Fyll ut adressen til ${artistForm.name}`}
                />
              )}
            </InputWrapper>
          </Wrapper>
        </Row>
        <Row>
          <Errormessage messages={errorMessage} />
        </Row>
        <Row>
          <Actions>
            <Button type="button" theme="primary" onClick={this.handleNext}>
              Neste
            </Button>
            <Button type="button" theme="secondary" onClick={this.handlePrevious}>
              Forrige
            </Button>
          </Actions>
        </Row>
      </>
    )
  }
}

JoinArtistLocation.propTypes = {
  currentUser: PropTypes.object, // eslint-disable-line react/forbid-prop-types
  artistTypes: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  artistForm: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  setData: PropTypes.func.isRequired,
  saveData: PropTypes.func.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
}

export default withRouter(JoinArtistLocation)
